body {
  font-family: 'Merriweather', serif;
  
}

/* @font-face {
  font-family: 'Roboto', sans-serif;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
} */
.header {
  text-align: center;
  font-size: 36px;
  margin-top: 15px;
  padding-right: 53px;
  width: 80%;
  margin-left: 10%;
  height: 54px;
}

form {
  width: 80%;
  margin-left: 10%;
  background: linear-gradient(0deg, #93bfef 30%, rgb(190 225 241) 100%);
  border-radius: .5%;
  padding: 30px;
  margin-bottom: 10%;
  box-shadow: 4px 4px 6px 0 rgb(10 10 10 / 40%);
}

.form-group{
  margin-bottom: 15px;
}
.formField {
  margin-top:2%;
  margin-bottom: 2%;

}

.form-label {
  font-weight: bold;
  margin-bottom: 0px;
}

.description {
  font-size: 15px;
  margin-bottom: 7px;
}

input, select {
  width: 85%;
}

#emails {
  height: 100px;
}

#hide{
  display : none;
}

#show{
  display: block;
}

#test-send-button, #live-send-button{
  margin: 15px;
  font-size: 20px;
  height: 45px;
  width: 125px;
  box-shadow: 4px 4px 6px 0 rgb(10 10 10 / 40%);
}
#live-send-button:hover {
  background-color: #28a745;
  border-color: #28a745;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5);
}
#test-send-button:hover{
  background-color: #28a745;
  border-color: #28a745;
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5);
}

.both-buttons {
  text-align: center;
}

.modal-content {
  box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5);
}